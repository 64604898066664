import { getAbout } from "../queries/about";
import { useQuery } from "@tanstack/react-query";
import { gqlAPI } from "../../graphQl/clientGraphQl";
import { s3Api } from "../../services/api";
import { configQuery } from "./blog";


const siteBackupDataFromS3 = async () => {
  try {
    const res = await s3Api("about").get()
    return res.data.data
  } catch (e) {
    console.warn('Both GRAPHQL and S3 requests failed!', e)
  }
}
const siteData = async () => {
  try {
    const res = await gqlAPI(getAbout);
    return res
    
  } catch(e) {
    console.warn('GraphQL Request failed! Fetching data from S3.', e)
    return siteBackupDataFromS3()
  }
}

export const useAbout = () => {
  return useQuery(["AboutPage"], siteData, configQuery);
}