import React from "react";
import { graphql } from "gatsby";

import { Seo } from "../../components/Seo";
import { useCarers } from "../../hooks/methods/carers";
import Layout from "../../templates/MainTemplate";
import { BannerCarreiras } from "../../view/carreiras/banner";
import { Benefits } from "../../view/carreiras/Benefits";
import { DepositionsCarreiras } from "../../view/carreiras/depositions";
import { LocationCinnecta } from "../../view/carreiras/locationCinnecta";
import { StampsCareers } from "../../view/carreiras/StampsCareers";
import { Vacancies } from "../../view/carreiras/Vacancies";
import { CultureCinnecta } from "../../view/cinnecta/culture";
import styled from "styled-components";

const DepositionsContainer = styled.div`
    margin: 80px 0;
    @media (max-width: 1203px) {
      margin: 0;
    }
`;


function Carreiras() {
  const { data, isSuccess } = useCarers();

  const {
    noVacancy,
    imageCareers,
    stampsCareers,
    openVacancies,
    titleVacancy,
    descriptionVacancies,
    bannerCareers,
    locationCinnecta,
    benefitsCinnecta,
    benefitsContCinnecta,
    depositionCollaborators,
  } = isSuccess && data?.careersPage;


  return (
    <>
      <Layout>
        <BannerCarreiras data={bannerCareers} />
        <DepositionsContainer>
          <DepositionsCarreiras data={depositionCollaborators} />
        </DepositionsContainer>
        <CultureCinnecta data={imageCareers} />
        <StampsCareers data={stampsCareers} />
        <Benefits
          benefitsCinnecta={isSuccess && benefitsCinnecta}
          benefitsContCinnecta={isSuccess && benefitsContCinnecta}
        />
        <Vacancies data={openVacancies} title={titleVacancy} descriptionVacancies={descriptionVacancies} noVacancy={noVacancy} />
        <LocationCinnecta data={isSuccess && locationCinnecta} />
      </Layout>
    </>
  );
}

export const Head = ({
  data: {
    cmsCareersPage: 
      {seo: { title: seoTitle, description: seoDescription, keywords, image }},
  },
}) => {
  const keywordsList = keywords.toString()
  return (
    <Seo title={seoTitle} description={seoDescription} keywords={keywordsList} url={image?.url || ""} />
  );
};

export const query = graphql`
  query PageQuery {
    cmsCareersPage {
      seo {
        title
        description
        keywords
        image {
          url
        }
      }
    }
  }
`;

export default Carreiras;