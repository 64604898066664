import styled from "styled-components";

export const ContainerVacancies = styled.section`
  width: 100%;

  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;

  margin: 2.813rem auto;
  gap: 0.5rem;
  padding: 1rem;

  span {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: Raleway;
    text-align: center;
    letter-spacing: 0.2px;
    color: var(--text);
  }
`;

export const CardVacancies = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  gap: 0.5rem;

  width: 100%;
  height: 100%;
  min-height: 409px;
  max-height: 409px;
  /* height: 100%;
  max-height: 16.9375rem; */

  background: #fcfcfc;
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 1.5625rem;

  flex: none;
  order: 2;
  flex-grow: 0;

  @media (max-width: 720px) {
    min-height: 360px;
    max-height: 360px;
  }


  div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  h2 {
    font-family: "Cabin";
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 36px;

    letter-spacing: 0.0156rem;

    color: #0055ff;
  }

  span {
    color: var(--blue-50);
    font-family: "Cabin";
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 36px;
    text-align: left;
  }

  p {
    color: var(--text);
    font-family: Raleway;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
    text-align: left;
  }
`;

export const WrapperCards = styled.div`
  width: 100%;
  max-width: 1220px;
  padding: 0 20px;
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 31px auto;
  gap: 1.25rem;

  @media (max-width: 1196px) {
    grid-template-columns: auto auto;
    max-width: 800px;
  }

  @media (max-width: 798px) {
    grid-template-columns: auto;
    max-width: 400px;
    padding: 0;

  }


  h2.no-vacancy {
    letter-spacing: 0.0125rem;
    font-family: Raleway;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: #6d6d6d;
  }
`;

