import React from "react";
import { Button } from "../../../components/Button/Button";
import { ContainerBanner, ContentBanner } from "./styledBanner";

export const BannerCarreiras = ({ data }) => {
  return (
    <ContainerBanner>
      <ContentBanner>
        <h1>{data?.titleBanner}</h1>
        <p>{data?.subTitleBanner}</p>
        <a href={data?.buttonSlug ? data?.buttonSlug : data?.buttonLink} target={!data?.buttonSlug && "blank"}>
          <Button variant="primary" label={data?.buttonText} />
        </a>
      </ContentBanner>

      <img src={data?.imageBanner?.url} alt="carreiras" loading="lazy" />
    </ContainerBanner>
  );
};
