import React from "react";
import { Link } from "gatsby";
import { Title } from "../../../components/Title/Title";
import { Button } from "../../../components/Button/Button";
import {
  CardVacancies,
  ContainerVacancies,
  WrapperCards,
} from "./StyledVacancies";

export const Vacancies = ({ data, noVacancy, title, descriptionVacancies }) => {
  return (
    <ContainerVacancies id="vacancies">
      <Title label={title} />
      {descriptionVacancies && <span>{descriptionVacancies}</span>}
      <WrapperCards>
        {data?.length > 0 ? (
          data?.map(
            ({
              area,
              jobTitle,
              descriptionJob,
              buttonSlug,
              buttonText,
              buttonLink,
            }) => (
              <CardVacancies key={jobTitle}>
                <div>
                  <span>{area}</span>
                  <h2>{jobTitle}</h2>
                  {descriptionJob && (
                    <p>
                      {descriptionJob?.length <= 305
                        ? descriptionJob
                        : descriptionJob.slice(0, 305) + "..."}
                    </p>
                  )}
                </div>
                {buttonSlug ? (
                  <Link to={`/${buttonSlug}`}>
                    <Button
                      type="button"
                      label={buttonText || "quero me candidatar"}
                    />
                  </Link>
                ) : (
                  <a href={buttonLink} target="_blank" rel="noreferrer">
                    <Button
                      type="button"
                      label={buttonText || "quero me candidatar"}
                    />
                  </a>
                )}
              </CardVacancies>
            )
          )
        ) : (
          <h2 className="no-vacancy">{noVacancy}</h2>
        )}
      </WrapperCards>
    </ContainerVacancies>
  );
};
